﻿//
// Variables
// --------------------------------------------------

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs: 480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min: @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone: @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm: 768px;
@screen-sm-min: @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet: @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md: 992px;
@screen-md-min: @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop: @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg: 1200px;
@screen-lg-min: @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop: @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-lg-min - 1);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
@grid-columns: 24;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width: 30px;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
@container-tablet: (720px + @grid-gutter-width);
//** For `@screen-sm-min` and up.
@container-sm: @container-tablet;

// Medium screen / desktop
@container-desktop: (940px + @grid-gutter-width);
//** For `@screen-md-min` and up.
@container-md: @container-desktop;

// Large screen / wide desktop
@container-large-desktop: (1140px + @grid-gutter-width);
//** For `@screen-lg-min` and up.
@container-lg: @container-large-desktop;


























body {
    height: 100%;
}


// 100% of the view height
.app,
.row-full,
.col-full,
.container-fluid {
    height: 100vh;
}

// 100% of the containing element
.row-fill,
.col-fill {
    height: 100%;
}


// Fixed container
//
.container {
    .container-fixed();

    @media (min-width: @screen-sm-min) {
        width: @container-sm;
    }

    @media (min-width: @screen-md-min) {
        width: @container-md;
    }

    @media (min-width: @screen-lg-min) {
        width: @container-lg;
    }
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.
.container-fluid {
    .container-fixed();
}


// Row
//
// Rows contain and clear the floats of your columns.
.row {
    .make-row();
}


// Columns
//
// Common styles for small and large grid columns
.make-grid-columns();


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.
.make-grid(xs);
.make-grid-additional(xs);

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.
@media (min-width: @screen-sm-min) {
    .make-grid(sm);
    .make-grid-additional(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.
@media (min-width: @screen-md-min) {
    .make-grid(md);
    .make-grid-additional(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.
@media (min-width: @screen-lg-min) {
    .make-grid(lg);
    .make-grid-additional(lg);
}













// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
.container-fixed(@gutter: @grid-gutter-width) {
    margin-right: auto;
    margin-left: auto;
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);
    &:extend(.clearfix all);
}

// Creates a wrapper for a series of columns
.make-row(@gutter: @grid-gutter-width) {
    margin-left: ceil((@gutter / -2));
    margin-right: floor((@gutter / -2));
    &:extend(.clearfix all);
}

// Generate the extra small columns
.make-xs-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    float: left;
    width: percentage((@columns / @grid-columns));
    min-height: 1px;
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);
}

.make-xs-column-offset(@columns) {
    margin-left: percentage((@columns / @grid-columns));
}

.make-xs-column-push(@columns) {
    left: percentage((@columns / @grid-columns));
}

.make-xs-column-pull(@columns) {
    right: percentage((@columns / @grid-columns));
}

// Generate the small columns
.make-sm-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);

    @media (min-width: @screen-sm-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}

.make-sm-column-offset(@columns) {
    @media (min-width: @screen-sm-min) {
        margin-left: percentage((@columns / @grid-columns));
    }
}

.make-sm-column-push(@columns) {
    @media (min-width: @screen-sm-min) {
        left: percentage((@columns / @grid-columns));
    }
}

.make-sm-column-pull(@columns) {
    @media (min-width: @screen-sm-min) {
        right: percentage((@columns / @grid-columns));
    }
}

// Generate the medium columns
.make-md-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);

    @media (min-width: @screen-md-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}

.make-md-column-offset(@columns) {
    @media (min-width: @screen-md-min) {
        margin-left: percentage((@columns / @grid-columns));
    }
}

.make-md-column-push(@columns) {
    @media (min-width: @screen-md-min) {
        left: percentage((@columns / @grid-columns));
    }
}

.make-md-column-pull(@columns) {
    @media (min-width: @screen-md-min) {
        right: percentage((@columns / @grid-columns));
    }
}

// Generate the large columns
.make-lg-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);

    @media (min-width: @screen-lg-min) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}

.make-lg-column-offset(@columns) {
    @media (min-width: @screen-lg-min) {
        margin-left: percentage((@columns / @grid-columns));
    }
}

.make-lg-column-push(@columns) {
    @media (min-width: @screen-lg-min) {
        left: percentage((@columns / @grid-columns));
    }
}

.make-lg-column-pull(@columns) {
    @media (min-width: @screen-lg-min) {
        right: percentage((@columns / @grid-columns));
    }
}



// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `@grid-columns`.

.make-grid-columns() {
    // Common styles for all sizes of grid columns, widths 1-12
    .col(@index) { // initial
        @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
        .col((@index + 1), @item);
    }

    .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
        @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
        .col((@index + 1), ~"@{list}, @{item}");
    }

    .col(@index, @list) when (@index > @grid-columns) { // terminal
        @{list} {
            position: relative;
            // Prevent columns from collapsing when empty
            min-height: 1px;
            // Inner gutter via padding
            padding-left: ceil((@grid-gutter-width / 2));
            padding-right: floor((@grid-gutter-width / 2));
        }
    }

    .col(1); // kickstart it
}

.float-grid-columns(@prefix) {
    .col(@index) { // initial
        @item: ~".col-@{prefix}-@{index}";
        .col((@index + 1), @item);
    }

    .col(@index, @list) when (@index =< @grid-columns) { // general
        @item: ~".col-@{prefix}-@{index}";
        .col((@index + 1), ~"@{list}, @{item}");
    }

    .col(@index, @list) when (@index > @grid-columns) { // terminal
        @{list} {
            float: left;
        }
    }

    .col(1); // kickstart it
}

.calc-grid-column(@index, @prefix, @type) when (@type = width) and (@index > 0) {
    .col-@{prefix}-@{index} {
        width: percentage((@index / @grid-columns));
    }
}

.calc-grid-column(@index, @prefix, @type) when (@type = push) and (@index > 0) {
    .col-@{prefix}-push-@{index} {
        left: percentage((@index / @grid-columns));
    }
}

.calc-grid-column(@index, @prefix, @type) when (@type = push) and (@index = 0) {
    .col-@{prefix}-push-0 {
        left: auto;
    }
}

.calc-grid-column(@index, @prefix, @type) when (@type = pull) and (@index > 0) {
    .col-@{prefix}-pull-@{index} {
        right: percentage((@index / @grid-columns));
    }
}

.calc-grid-column(@index, @prefix, @type) when (@type = pull) and (@index = 0) {
    .col-@{prefix}-pull-0 {
        right: auto;
    }
}

.calc-grid-column(@index, @prefix, @type) when (@type = offset) {
    .col-@{prefix}-offset-@{index} {
        margin-left: percentage((@index / @grid-columns));
    }
}

// Basic looping in LESS
.loop-grid-columns(@index, @prefix, @type) when (@index >= 0) {
    .calc-grid-column(@index, @prefix, @type);
    // next iteration
    .loop-grid-columns((@index - 1), @prefix, @type);
}


* {
    .box-sizing(border-box);
}

*:before,
*:after {
    .box-sizing(border-box);
}


.box-sizing(@boxmodel) {
    box-sizing: @boxmodel;
}

.clearfix {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }

    &:after {
        clear: both;
    }
}




.loop-calc-heights(@index, @prefix) when (@index > 0) {
    .@{prefix}-height-@{index} {
        height: percentage(@index/ 100);
    };

    .loop-calc-heights((@index - 5), @prefix);
}


// Create grid for specific class
.make-grid(@prefix) {
    .float-grid-columns(@prefix);
    .loop-grid-columns(@grid-columns, @prefix, width);
    .loop-grid-columns(@grid-columns, @prefix, pull);
    .loop-grid-columns(@grid-columns, @prefix, push);
    .loop-grid-columns(@grid-columns, @prefix, offset);
}

// Create additional functionality like collapse, center, text-right, text-left .....
.make-grid-additional(@prefix) {

    .@{prefix}-row-center {
        display: table;
        width: 100%;
    }

    .@{prefix}-row-center > div,
    .@{prefix}-row-center > form,
    .@{prefix}-row-center > input {
        display: table-cell;
        vertical-align: middle;
        float: none;
    }

    .@{prefix}-col-center > div,
    .@{prefix}-col-center > input,
    .@{prefix}-col-center > form {
        display: table;
        margin-left: auto;
        margin-right: auto;
        float: none;
    }

    .@{prefix}-col-no-gutter {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }




    .@{prefix}-hide {
        visibility: hidden;
        display: none;
    }

    .@{prefix}-show {
        visibility: visible;
        display: block;
    }


    .@{prefix}-text-center {
        text-align: center;
    }

    .@{prefix}-text-left {
        text-align: left;
    }

    .@{prefix}-text-right {
        text-align: right;
    }

   

    .loop-calc-heights(100, @prefix);
}



.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}
